import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivate } from '@angular/router';
import { AuthService } from '../service/auth.service';
import { PATH_MENU } from '../config/global';
import { CurrentFiltersService } from 'src/app/shared/currentFilters';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private currentFiltersService: CurrentFiltersService ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      const user = this.authService.getUser();
      const urls = state.url.substring(1).split('/');
      this.currentFiltersService.assingChangeRouter(urls);
      return true;
      /*
      const currentModule = urls[0];
      if (urls.length > 1) {
        const currentPage = urls[1];
      }
      if (currentModule === '') {
        return true;
      } else {
        if (user.visibleModules.length === 0) {
          this.authService.logout();
          this.router.navigate(['/iniciar-sesion']);
          return false;
        } else if (user.visibleModules.length === 1) {
          if (user.visibleModules[0].moduleIdentifier !== currentModule) {
            this.router.navigate([
              '/' + user.visibleModules[0].moduleIdentifier
            ]);
            return false;
          } else {
            return true;
          }
        } else {
          if (
            !user.visibleModules.find(module => module.moduleIdentifier === currentModule)
          ) {
            if (currentModule !== '') {
              this.router.navigate(['/']);
              return false;
            } else {
              return true;
            }
          } else {
            return true;
          }
        }
      }*/
    } else {
      this.authService.logout();
      this.router.navigate([PATH_MENU.logging.path]);
      return false;
    }
  }
}
