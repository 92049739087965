import { Menu } from "../model/interface/menu.interface";


export const PATH_MENU = {
   /*product : {
     name: 'Productos',
     path: 'product',
     route:'product'
  }, */
  reserve : {
    name: 'Reserva de Stock',
    path: '/home/reserve',
    route:'reserve'
  },
  stock : {
    name: 'Cargar Stock',
    path: 'stock',
    route: 'stock'
  },
  process : {
    name: 'Procesos',
    path: '/home/process',
    route:'process'

  },
  processDetail : {
    name: 'Detalle del proceso',
    path: 'processDetail',
    route:'processDetail'

  },
  home: {
    name: 'Home',
    path: '/home',
    route: 'home'
  },
  logging: {
    name: 'Iniciar sesion',
    path: 'iniciar-sesion',
    route:'iniciar-sesion'
  },
  reserveDetail: {
    name: 'Detalle de la reserva',
    path: 'reserveDetail',
    route:'reserveDetail'
  },
  audit : {
    name: 'Auditoria',
    path: '/home/audit',
    route:'audit'

  },


}

export interface IMENU_PATH  {
  product : any,
  reserve : any,
  stock : any,
  settings : any,
  home: any,
  logging: any
}

export let menuList :Menu[] = [
  /*{
    module: 'product',
    name: PATH_MENU.product.name,
    link: PATH_MENU.product.path,
    icon: 'dialpad'
  },*/ {
    module: 'stock',
    name: PATH_MENU.stock.name,
    link: PATH_MENU.stock.path,
    icon: 'production_quantity_limits'
  }, {
    module: 'stock-reserve',
    name: PATH_MENU.reserve.name,
    link: PATH_MENU.reserve.path,
    icon: 'inventory_2'
  }, {
    module: 'jobs',
    name: PATH_MENU.process.name,
    link: PATH_MENU.process.path,
    icon: 'settings'
  }, {
    module: 'audit',
    name: PATH_MENU.audit.name,
    link: PATH_MENU.audit.path,
    icon: 'schedule'
  }
];

export function getTitleFromMenu(menuConfig : any, component: string): any {
  return menuConfig[component];

}