import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Notification } from 'src/app/core/model/interface/modal-notification';


export const SESSION_EXPIRED = {
  typeModal: 'invalid',
  title: 'Tiempo de inactividad máximo cumplido',
  valueButton: 'Aceptar',
  color: 'warn',
  icon: 'warning'
};

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit {
  /**
   * TODO: Descripcion de boton Cancelar
   * valor que hace referencia al botón cancelar
   */
  public buttonSecond = 'Cancelar';
  /**
   * TODO: Configuracion de MODAL_CONFIRM
   * valor que son requeridas para configurar el modal
   */
  public description: Notification = {

  };

  classButton: any;
  constructor(
    public dialogRef: MatDialogRef<ModalConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    this.description.title = SESSION_EXPIRED.title;
    this.description.superTitle = SESSION_EXPIRED.title;
    this.description.color = SESSION_EXPIRED.color;
    this.description.iconSVG = SESSION_EXPIRED.icon;
    this.description.typeModal = SESSION_EXPIRED.typeModal;
    this.description.valueButton = SESSION_EXPIRED.valueButton;

    this.classButton = this.description.color;
  }

  /**
   * TODO: función para CERRAR_MODAL
   * Está función nos ayudara a cerrar directamente el modal
   */
  onClickClose(): void {
    this.dialogRef.close();
  }
  showButtonExit(): boolean {
    return this.description.typeModal === 'notification' ||  this.description.typeModal === 'invalid' ||  this.description.typeModal === 'accept';
  }

  showButtonCancel(): boolean {
    return this.description.typeModal != 'invalid' && this.description.typeModal != 'accept';
  }

}
