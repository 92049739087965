import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './components/auth/login/login.component';
import { AuthGuard } from './core/guards';
import { PATH_MENU } from './core/config/global';

const routes: Routes = [ 
  {
    path: '', redirectTo:PATH_MENU.home.path, pathMatch: 'full'
  },
  {
    path: PATH_MENU.logging.route, component: LoginComponent
  },
  {
    path: PATH_MENU.home.route, loadChildren: ()=> import('./components/dashboard/dashboard.module').then(x=> x.DashboardModule), canActivate: [AuthGuard],
  },
  {
    path: '**', redirectTo:PATH_MENU.home.path, pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
