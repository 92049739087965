<div *ngIf="description">

  <mat-dialog-content>
    <span class="uk-text-subheading" *ngIf="description.superTitle">{{ description.superTitle }}</span>
    <mat-icon [color]="description.color" *ngIf="description.icon">{{ description.icon }}</mat-icon>
    <mat-icon
      class="icon-load"
      [ngClass]="description.iconSVG"
      [svgIcon]="description.iconSVG"
      *ngIf="description.iconSVG"
    ></mat-icon>
    <span class="title" [innerHTML]="description.title"></span>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button *ngIf="showButtonCancel()"
      mat-button
      id="cancel"
      (click)="onClickClose()"
      class="button-cancel"
      type="button"> {{ description.valueCancelButton ? description.valueCancelButton : buttonSecond }}
    </button>
    <button id="exit2" *ngIf="showButtonExit()"
      mat-flat-button
      color="warn"
      class="button-primary-event description.color"
      [ngClass]="classButton"
      [mat-dialog-close]="description.typeModal">
      {{ description.valueButton }}
    </button>
  </mat-dialog-actions>
</div>
