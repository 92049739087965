import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthService } from './auth.service';
import { ApiService } from './api.service';
import { SnackbarService } from 'src/app/shared/component/snackbar/snackbar.service';
import { ProductService } from './product.service';
import { StockService } from './stock.service';
import { HttpTokenInterceptor } from '../interceptor/http-token.interceptor';
import { FilterStockService } from './filter-stock.service';
import { StockReserveService } from './stock-reserve.service';
import { ProcessService } from './process.service';
import { LoaderService } from './loader.service';

//import { HttpTokenInterceptor, LoadingInterceptor, ErrorInterceptor } from './helpers';

@NgModule({
  imports: [CommonModule],
  providers: [
     { provide: HTTP_INTERCEPTORS, useClass: HttpTokenInterceptor, multi: true },
  //  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  //  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  //  { provide: HTTP_INTERCEPTORS, useClass: MocksInterceptor, multi: true },
    ApiService,
    AuthService,
    SnackbarService,
    ProductService,
    StockService,
    FilterStockService,
    StockReserveService,
    ProcessService,
    LoaderService
  ]
})
export class CoreModule {}
