import { Injectable } from "@angular/core";
import { Stock } from "../model/interface/stock.interface";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { StockFilter } from "../model/interface/request/stock-filter.interface";
import { ConfirmReserveRequest, StockReserve } from "../model/interface/request/stock-reserve.interface";
import { StockReserveFilter } from "../model/interface/request/stock-reserve-filter.interface";
import { StockReserveResponse } from "../model/interface/stock.-reserve.interface";

@Injectable()
export class StockReserveService {

 constructor(private apiClient : ApiService) {}

getReserveStocksByFilter(sku: string, entityCode: string):Observable<StockReserveResponse> {
  let request: StockReserveFilter[] = [ {
      skuCode: sku,
      entityCode: entityCode
    } ]
  return this.apiClient.post(`${this.apiClient.centralizedStockReserve}/get/reserve?page`, request).pipe(map(data => data));
 }

 getReserveStocksByReserveCode(reserveCode:string):Observable<StockReserve> {
  return this.apiClient.get(`${this.apiClient.centralizedStockReserve}/get/reserve/reservecode/${reserveCode}`).pipe(map(data => data));
 }



 createStockReserve(stockReserve: StockReserve):Observable<any> {
  return this.apiClient.post(`${this.apiClient.centralizedStockReserve}/reserve`, stockReserve).pipe(map(data => data));
 }

 confirmStockReserve(stockReserve: ConfirmReserveRequest):Observable<any> {
  return this.apiClient.post(`${this.apiClient.centralizedStockReserve}/reserve/confirm`, stockReserve).pipe(map(data => data));
 }
 
 cancelStockReserve(stockReserve: ConfirmReserveRequest):Observable<any> {
  return this.apiClient.post(`${this.apiClient.centralizedStockReserve}/reserve/cancel`, stockReserve).pipe(map(data => data));
 }

}
