import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { PATH_MENU } from 'src/app/core/config/global';
import { AuthService } from 'src/app/core/service/auth.service';
import { CurrentFiltersService } from 'src/app/shared/currentFilters';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public form: FormGroup;
  public pattern: RegExp = /^([0-9a-zA-Z'áéíóúñüÁÉÍÓÚÑÜ\s\'\-\_])+$/;
  public isNotProd: boolean = true;
  public hidePassword = true;
  public isSubmitting = false;
  version = ''; 
  private txtInvalido = 'Campo obligatorio';
  public validation_messages = {
    user: [
      { type: 'required', message: this.txtInvalido },
      { type: 'pattern', message: 'Caracter inválido' },
      { type: 'maxlength', message: 'Máximo 20 dígitos' }
    ],
    password: [
      { type: 'required', message: this.txtInvalido },
      { type: 'minlength', message: '' },
      { type: 'maxlength', message: 'Máximo 20 dígitos' }
    ],
  
    passwordNew: [{ type: 'required', message: this.txtInvalido }],
    passwordNewTwo: [{ type: 'required', message: this.txtInvalido }]
  }
  constructor(private router: Router, private authService: AuthService,  formBuilder: FormBuilder, private currentFilterService: CurrentFiltersService) { 

    this.form = formBuilder.group({
      username: [
        null,
        [
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(20),
          Validators.pattern(this.pattern)
        ]
      ],
      password: [
        '',
        [Validators.required, Validators.minLength(4), Validators.maxLength(20)]
      ]
    });
  }



  ngOnInit(): void {
  }

  onSubmit() {
    this.isSubmitting = true;
    const valueLogin = {... this.form.value}
    this.form.reset();
    valueLogin.username = valueLogin.username.toUpperCase();
    this.authService.login(valueLogin).then(() => {
      this.isSubmitting = false;

      if (this.authService.getUser()) {
        this.currentFilterService.sessionActivate();
      }
      this.router.navigate([PATH_MENU.home.path]);
    }).catch(() => {
      this.isSubmitting = false;
    })

  }

}
