import { Injectable } from '@angular/core';

import { SnackbarComponent } from './snackbar.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { alertMessage, getMessage } from '../../constant/alert-message';

interface SnackbarOptions {
  duration?: number;
  autoClose?: boolean;
  data?: any;
  verticalPosition?: 'top' |'bottom';
  horizontalPosition?: 'right';
}

@Injectable({
  providedIn: 'root'
})
export class SnackbarService {
  constructor(public snackBar: MatSnackBar) {}

  show(
    data: any,
    type: 'success' | 'error' | 'warning' | 'info',
    options?: SnackbarOptions
  ) {
    const config = new MatSnackBarConfig();
    this.style(config, type);
    const defaultOptions = {
      duration: 4000,
      autoClose: true,
      data: this.message(data,type)
    };
    options = { ...defaultOptions, ...options };
    if (options.autoClose) {
      config.duration = options.duration;
    }
    config.verticalPosition = 'top';
    config.data = options;
    this.snackBar.openFromComponent(SnackbarComponent, config);
  }

  hide() {
    this.snackBar.dismiss();
  }

  private message(data: any, type: string) {
    const exist = Object.keys(alertMessage).filter(
      item =>
        typeof data === 'string' && data.toLowerCase() === item.toLowerCase()
    );
    let messages = exist.length ? getMessage(alertMessage, data) : this.getMessages(data);
    messages = !messages && type === 'error' ? 'Hubo un error en el servicio, intente nuevamente' : messages; 
    return messages
  }

  getMessages(data:any):string {
    if (typeof data === 'string') {
      return data;
    }
    return data.message
  }

  private style(
    config: MatSnackBarConfig,
    type: 'success' | 'error' | 'warning' | 'info'
  ) {
    switch (type) {
      case 'success':
        config.panelClass = ['success-snackbar'];
        break;
      case 'error':
        config.panelClass = ['error-snackbar'];
        break;
      case 'warning':
        config.panelClass = ['warning-snackbar'];
        break;
      case 'info':
        config.panelClass = ['info-snackbar'];
        break;
    }
  }
}
