import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '../../../environments/environment';
import { LoaderService } from '../service/loader.service';

@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  constructor(private loaderService:LoaderService){

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let session:any = localStorage.getItem(environment.sessionAuth);
    if (session) {
      session =  JSON.parse(session);
    }
    const headersConfig:any = {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    };
    if (session &&  session.user &&  session.user.token_type && session.user.access_token) {
      headersConfig['Authorization'] = session.user.token_type + ' ' + session.user.access_token;
    }
    request = request.clone({ setHeaders: headersConfig });
    this.loaderService.requestStarted();
    return this.handler(next,request)
  }

  handler(next: HttpHandler, request: HttpRequest<any>) {
    return next.handle(request)
        .pipe(tap( (event)=> {
          if (event instanceof HttpResponse) {
           this.loaderService.requestEnded()
          }
      
        },
        (error : HttpErrorResponse) => {
          this.loaderService.resetSpinner();
          throw error
        }
    ));
  }
}
