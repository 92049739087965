import { Injectable } from "@angular/core";
import { Stock, StockPaginate } from "../model/interface/stock.interface";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { StockFilter } from "../model/interface/request/stock-filter.interface";
import { StockSave, StockUpdate } from "../model/interface/request/stock-save.interface";
import { StockReserve } from "../model/interface/request/stock-reserve.interface";


const STOCK: Stock[] = [ {
  stockId: 1,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 1,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
}, {
  stockId: 2,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 1,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
}, {
  stockId: 3,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 2,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 4,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 2,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 5,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 3,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 6,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 3,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 7,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 4,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 8,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 4,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 9,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 5,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 10,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 5,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 11,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 6,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 12,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 6,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 13,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 7,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 14,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 7,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 15,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 8,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 16,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 8,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 17,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 9,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 18,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 9,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
} ,{
  stockId: 1,
  entityCode: 'OE-128',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 10,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
},{
  stockId: 2,
  entityCode: 'OE-119',
  quantity: 2,
  cost: 39,
  price: 10,
  active: true,
  stockType : 'DISP',
  productId: 10,
  unitMeasurement: 'UN',
  isEcommerce: false,
  companyCode: 'OE',
  entityType: 'TD'
}   ];

@Injectable()
export class StockService {


 updateReserveStock(stock: Stock) {
    const stockFind = STOCK.find(stock => stock.stockId === stock.stockId);
    if (stockFind) {
      stockFind.quantityReserve = stock.quantityReserve;
    }
 }
 
 constructor(private apiClient : ApiService) {}

 createStock(stock:StockSave):Observable<any> {
  return this.apiClient.post(`${this.apiClient.centralizedStockManagement}/stock`, stock).pipe(map(data => data));
 }
 
 updateStock(stock:StockUpdate, entityCode: string, skuCode: string):Observable<any> {
  return this.apiClient.put(`${this.apiClient.centralizedStockManagement}/stock/entity/${entityCode}/skucode/${skuCode}`, stock).pipe(map(data => data));
 }

 getStocksByFilter(sku: string, entityCode: string, page: number, size: number = 10):Observable<StockPaginate> {
  let request: StockFilter = {
    stocks: [ {
      skuCode: sku,
      entityCode: entityCode
    } ]
  }
  return this.apiClient.post(`${this.apiClient.centralizedStockRead}/stock?page=${page}&size=${size}`, request).pipe(map(data => data));
 }

 getStockBySkuAndEntity(skuCode: string, entityCode: string):Observable<Stock[]> {
  return this.apiClient.get(`${this.apiClient.centralizedStockManagement}/stock/entity/${entityCode}/skucode/${skuCode}`).pipe(map(data => data));
 }

 desactivateStock(skuCode: any, entityCode: string):Observable<Stock[]> {
  return this.apiClient.put(`${this.apiClient.centralizedStockManagement}/stock/deactivate/skucode/${skuCode}/entity/${entityCode}`).pipe(map(data => data));
 }


 getStockById(stockId: number | undefined):Observable<Stock> {
  return this.apiClient.get(`${this.apiClient.centralizedStockManagement}/stock/id/${stockId}`).pipe(map(data => data));
 }

 createStockReserve(stockReserve: StockReserve):Observable<any> {
  return this.apiClient.post(`${this.apiClient.centralizedStockReserve}/reserve`, stockReserve).pipe(map(data => data));
 }

 confirmStockReserve(stockReserve: StockReserve):Observable<any> {
  return this.apiClient.post(`${this.apiClient.centralizedStockReserve}/reserve/confirm`, stockReserve).pipe(map(data => data));
 }



 getAllEntityCode():string[] {
  return STOCK.map(stock => stock.entityCode);
 }

 getStockByProduct(productId: number):Stock[] {
  return STOCK.filter(stock => stock.productId === productId);

 }

}
