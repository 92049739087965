import { Injectable } from "@angular/core";
import { Product } from "../model/interface/product.interface";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";

@Injectable()
export class ProductService {
  constructor(private apiClient : ApiService) {}

 getProductBySku(skuCode:string, limite: number = 100):Observable<Product[]> {
   return this.apiClient.get(`${this.apiClient.centralizedStockManagement}/product/skucode/${skuCode}/limit/${limite}`).pipe(map(data => data));
}

 getProductById(productId:number): Observable<Product> {
   return this.apiClient.get(`${this.apiClient.centralizedStockManagement}/product/id/${productId}`).pipe(map(data => data));
 }
 
}
