import { Injectable } from "@angular/core";
import { Stock } from "../model/interface/stock.interface";
import { ApiService } from "./api.service";
import { Observable, Subject, map } from "rxjs";
import { StockFilter } from "../model/interface/request/stock-filter.interface";
import { StockSave, StockUpdate } from "../model/interface/request/stock-save.interface";
import { StockReserve } from "../model/interface/request/stock-reserve.interface";


@Injectable()
export class FilterStockService {

 changeFilter: Subject<Object> = new Subject<Object>();
 constructor() {}

 
}
