import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

import { environment } from "../../../environments/environment";
import { SnackbarService } from "src/app/shared/component/snackbar/snackbar.service";

@Injectable()
export class ApiService {
  // login = '/api/auth/login';
  login = `${environment.apiLogin}/auth/login`;
  loginAuthorize = `${environment.apiLogin}/auth/user-identity/authorize`;
  userIdentity = `${environment.apiLogin}/auth/user-identity`;
  loginAuthorizeStock = `${environment.apiLogin}/authorize/`;
  userEntity = `${environment.apiLogin}/user`;
  changePassword = `${environment.apiLogin}/auth/user-identity/change-password`;
  centralizedStockRead = `${environment.apiStockRead}`;
  centralizedStockManagement = `${environment.apiStockManagement}`;
  centralizedStockReserve = `${environment.apiStockReserve}`;
  centralizedStockJob = `${environment.apiStockJob}`;


  constructor(
    private http: HttpClient,
     public snackbarService: SnackbarService
  ) {}

  public formatErrors(error: HttpErrorResponse) {
    const messageError = error.error ? error.error : error;
    this.snackbarService.show(messageError, "error");
    return throwError(() => (new Error(JSON.stringify(messageError))));
  }

  get(path: string,params?: any): Observable<any> {
    return this.http.get(path, { params }).pipe(
      catchError((error) => {
        return this.formatErrors(error);
      })
    );
  }

  put(path: string, body: Object = {}): Observable<any> {
    return this.http.put(path, JSON.stringify(body)).pipe(
      catchError((error) => {
        return this.formatErrors(error);
      })
    );
  }

  patch(path: string, body: Object = {}): Observable<any> {
    return this.http.patch(path, JSON.stringify(body)).pipe(
      catchError((error) => {
        return this.formatErrors(error);
      })
    );
  }

  post(path: string, body: Object = {}, header?: any): Observable<any> {
    return this.http.post(path, body, header).pipe(
      catchError((error) => {
        return this.formatErrors(error);
      })
    );
  }

 delete(path: string): Observable<any> {
    return this.http.delete(path).pipe(
      catchError((error) => {
        return this.formatErrors(error);
      })
    );
  }
}
