<span
  id="snackbarMessage"
  class="uk-text-body"
  [innerHtml]="options.data"
></span>
<button
  *ngIf="!options.autoClose"
  id="closeSnackbarMessage"
  mat-button
  class="dialog-close"
  (click)="hide()"
>
  <mat-icon>close</mat-icon>
</button>
