import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, map, startWith } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { Apollo, gql } from 'apollo-angular'
import { Entity } from 'src/app/core/model/interface/entity';

@Component({
  selector: 'app-entity-filter',
  templateUrl: './entity-filter.component.html',
  styleUrls: ['./entity-filter.component.scss']
})
export class EntityFilterComponent implements OnInit, OnDestroy {

  @Input() 
  public entityType:string | any;

  @Input() 
  public readOnly:boolean = false;;

  formControlEntityCodeSearch = new FormControl();

  @Input() 
  entityTypeCode: string = '';

  public filteredEntityCodeOptions: Observable<Entity[]>;
  public listEntitys:any[] = []

  @Output() 
  selectedEntity: EventEmitter<any> = new EventEmitter();

  constructor(private apollo: Apollo) {
    //this.listEntity();

      this.filteredEntityCodeOptions = this.formControlEntityCodeSearch.valueChanges.pipe(
        startWith(''),
        map(value =>  this._filterEntity(value || ''))
      );
     /*
     TODO PARA OBTENER CAMBIOS DESDE UN COMPONENTE PADRE
         this.filterStockService.changeFilter.subscribe( data => {
         });
     */
  }
  
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.formControlEntityCodeSearch.setValue(this.entityType);
    this.listEntity().subscribe(response => {
      this.listEntitys = response;
      if (this.entityType && this.readOnly) {
        const entity = this.listEntitys.find(ent => ent.entityCode === this.entityType );
        this.formControlEntityCodeSearch.setValue(entity);
      }
      this.filteredEntityCodeOptions = this.formControlEntityCodeSearch.valueChanges.pipe(
        startWith(''),
        map(value =>  this._filterEntity(value || ''))
      );
    });

  }

  listEntity() {
   const entityType = this.entityTypeCode ? this.entityTypeCode : 'TD'
   return this.apollo
      .query({
        query: gql`
          query listEntities(
            $limit: Int
            $offset: Int
            $entityType: EntityType
            $enabled: Boolean
          ) {
            listEntities(
              limit: $limit
              offset: $offset
              entityType: $entityType
              enabled: $enabled
            ) {
              entityCode
              entityType
              entityName
              entityAddress
              active
              enabledDispatch
              enabledRetire
              enabledPicking
              dispatchMaxReprograms
              enableMerchandiseCollect
              pickingSystemCode
              ubigeoCode
              managingEntities
            }
          }
        `,
        variables: {
          entityType: entityType,
          enabled: true,
        }
      })
        .pipe(
          map((response :any)=>{
            return response?.data?.listEntities;;
          })
        )
  }

  
  selectEntity(entity: any):void {
    this.entityType = undefined;
    if (entity.value) {
      this.entityType = entity.value
    }
    this.selectedEntity.emit(this.entityType);
  }

  
  displayFnEntity(entity:any):string {
    return entity ? entity.entityCode + " - " + entity.entityName : '';
  }

  private _filterEntity(value:string): Entity[] {
    const filterValue = value.toString().toLowerCase();
    return this.listEntitys.filter((option: Entity) => {
      return (option.entityName.toLowerCase().match(filterValue)  || option.entityCode.toLowerCase().match(filterValue) ) || filterValue === ''
    } );
  }

  cleanEntity(): void {
    this.formControlEntityCodeSearch.setValue(null);
    this.selectedEntity.emit(undefined);
  }
}
