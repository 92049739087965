import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PATH_MENU, getTitleFromMenu } from 'src/app/core/config/global';

@Injectable({ providedIn: 'root' })

export class CurrentFiltersService {
  private currentFiltersSource = new BehaviorSubject<any>(null);
  public currentFilters$ = this.currentFiltersSource.asObservable();

  private currentSessionSource = new BehaviorSubject<any>(null);
  public currentSessionFilters$ = this.currentSessionSource.asObservable();

  //Config para evento cuando cambia cada ruta
  changeRouter: Subject<Object | any> = new Subject<Object | any>();

  constructor() { }

  asignFilters(filters:any) {
    this.currentFiltersSource.next(filters);
  }

  clearCurrentFilters() {
    this.currentFiltersSource.next(null);
  }

  sessionActivate() {
    this.currentSessionSource.next(true);
  }

  assingChangeRouter(router:string[]) {
    if (router && router.length > 0) {
      let namePath:string = router[router.length -1];
      if (namePath.includes('?')) {
        namePath = namePath.split('?')[0];
      }
      this.changeRouter.next(getTitleFromMenu(PATH_MENU, namePath)?.name);
    }
  }

  clearAssingChangeRouter() {
    this.changeRouter.next(null);
  }
}
