import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './material/material.module';
import { StockFilterComponent } from './component/product-filter/stock-filter.component';
import { LoaderComponent } from './component/loader/loader.component';
import { EntityFilterComponent } from './component/entity-filter/entity-filter.component';
import { FilterComponent } from './component/filter/filter.component';
@NgModule({
  declarations: [
    StockFilterComponent,
    EntityFilterComponent,
    FilterComponent,
    LoaderComponent
  ],
  imports: [
    CommonModule,
    FormsModule, 
    ReactiveFormsModule,
    MaterialModule
  ],
  exports : [
    CommonModule,
    MaterialModule,
    FormsModule, 
    ReactiveFormsModule,
    StockFilterComponent,
    LoaderComponent,
    FilterComponent,
    EntityFilterComponent,
  ]
})
export class SharedModule { }
