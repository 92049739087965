<mat-expansion-panel [expanded]="true" #filters class="mb-4">  
    <mat-expansion-panel-header>  
      <mat-panel-title class="uk-text-subheading">
        Filtrar Stock por:
      </mat-panel-title>  
    </mat-expansion-panel-header>

    <mat-card class="margin-bottom-20">
        <app-stock-filter (selectedProduct)="selectedProduct($event)"></app-stock-filter>
        <app-entity-filter (selectedEntity)="selectedEntity($event)"></app-entity-filter>

   <!--  
          <mat-form-field class="example-full-width  custom-width">
              <mat-label>Buscar entidad</mat-label>
              <input type="text"
                     matInput
                     [formControl]="formControlEntityCodeSearch"
                     [matAutocomplete]="autoEntity">
             <button *ngIf="formControlEntityCodeSearch.value" type="button" mat-icon-button matSuffix>
                 <mat-icon class="button-clear-filter" (click)="cleanEntity()" matTooltip="Borrar">highlight_off</mat-icon>
             </button>
              <mat-autocomplete autoActiveFirstOption #autoEntity="matAutocomplete"  (optionSelected)="selectEntity($event.option)" [displayWith]="displayFnEntity">
                <mat-option value="">Seleccione una opción</mat-option>
                <mat-option *ngFor="let option of filteredEntityCodeOptions | async" [value]="option">
                  {{ option.entityName }}
                </mat-option>
              </mat-autocomplete>
          </mat-form-field>
          -->
    
    </mat-card>
    <button mat-raised-button color="primary" class="position-right" (click)="filters.close(); searchStock()" [disabled]="enabledButton()" type="button">Buscar</button>
  </mat-expansion-panel>