import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Observable } from 'rxjs';
import { AuthService } from './core/service/auth.service';
import { ModalConfirmComponent } from './shared/component/modal/modal-confirm/modal-confirm.component';
import { CurrentFiltersService } from './shared/currentFilters';
import { PATH_MENU } from './core/config/global';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'centralized-stock-webbapp';
  isLoggedIn$: Observable<boolean>;
  menuList = [];
  constructor( private router: Router, private authService: AuthService, private currentFilterService: CurrentFiltersService, 
    private userIdle: UserIdleService, public dialog: MatDialog) {

    this.isLoggedIn$ = this.authService.isLoggedIn;
    this.isLoggedIn$.subscribe(isLoggedIn => {
      if (isLoggedIn) {
         this.router.navigateByUrl(PATH_MENU.home.path);
      }
    });
    
  }
  ngOnInit(): void {
    this.showSession();
    this.validateSession();
  }

  showSession() {
    this.currentFilterService.currentSessionFilters$.subscribe(value => {
      if (value) {
         this.userIdle.resetTimer();
      }
    });
  }

  validateSession() {
    let openModal = false;
    this.userIdle.startWatching();
    this.userIdle.onTimerStart().subscribe(() => { });
    this.userIdle.onTimeout().subscribe(() => {
      if (this.authService.getUser() && !openModal) {
        openModal = true;
        const openGrabar = this.dialog.open(ModalConfirmComponent, {
          data: 'sessionExpired'
        });
        openGrabar.afterClosed().subscribe(() => {
            openModal = false;
            this.authService.logout();
            window.location.reload();
        });
      }
    });
  }

   
}
