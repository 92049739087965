import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AuthModule } from './components/auth/auth.module';
import { HttpClientModule } from '@angular/common/http';
import { CoreModule } from './core/service/core.module';
import { AuthGuard, NoAuthGuard } from './core/guards';
import { UserIdleModule } from 'angular-user-idle';
import { ModalConfirmComponent } from './shared/component/modal/modal-confirm/modal-confirm.component';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { ApiService } from './core/service/api.service';
//Config graphql
/*
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { DefaultOptions } from 'apollo-client';
import { GraphQLModule } from './graphql.module';
import { ApolloModule, Apollo } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http'; */

//import { ApolloModule, APOLLO_OPTIONS } from "apollo-angular";
//import { HttpLinkModule, HttpLink } from "apollo-angular-link-http";
//import { InMemoryCache } from "apollo-cache-inmemory";

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http'
import { InMemoryCache } from '@apollo/client/core'

@NgModule({
  declarations: [
    AppComponent,
    ModalConfirmComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AuthModule,
    HttpClientModule,
    CoreModule,
    SharedModule,
    UserIdleModule.forRoot({ idle: 1800, timeout: 1, ping: 120 }),
  //  GraphQLModule,
   // HttpLinkModule,
    ApolloModule,

  ],

  providers: [AuthGuard, NoAuthGuard,{
    provide: APOLLO_OPTIONS,
    useFactory: (httpLink: HttpLink) => {
      return {
        cache: new InMemoryCache(),
        link: httpLink.create({
//          uri: 'https://48p1r2roz4.sse.codesandbox.io',
          uri : environment.apiEntity

        })
      }
    },
    deps: [HttpLink]
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  /*
  constructor(apollo: Apollo, httpLink: HttpLink, public apiService: ApiService) {
    const http = httpLink.create({
      uri: environment.apiEntity
    });

    const authLink = setContext(async (_, { headers }) => {
      const dataSssion = localStorage.getItem(environment.sessionAuth);
      if (dataSssion) {
        const token = await JSON.parse(dataSssion).user.access_token;
        return {
          headers: { authorization: token ? `Bearer ${token}` : '' }
        };
      }
      return  {}
    });
    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    };
    apollo.create({
      link: authLink.concat(http),
      cache: new InMemoryCache(),
      defaultOptions: <DefaultOptions>defaultOptions
    });
  } */
 }
