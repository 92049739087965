import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NoAuthGuard } from 'src/app/core/guards';
import { LoginComponent } from './login/login.component';
//import { AuthUserComponent } from './auth-user.component';
// import { NoAuthGuard, AuthGuard } from '../core';

const routes: Routes = [
  {
    path: 'iniciar-sesion',
    component: LoginComponent,
    canActivate: [NoAuthGuard]
  },
  /*{
    path: '',
    component: AuthUserComponent,
    canActivate: [AuthGuard]
  } */
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthRoutingModule {}
