import { Component, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/core/model/interface/product.interface';
import { EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit, OnDestroy {

  @Output() filterStockEvent: EventEmitter<any> = new EventEmitter();

  @Input() requiredAllFilter: boolean = false;

  @Input() entityTypeCode: string = '';

  public productStock:Product | any;
  public entityType:string | any;
  public filterSkuReadOnly: boolean = false;

  constructor() {
     /*
     TODO PARA OBTENER CAMBIOS DESDE UN COMPONENTE PADRE
         this.filterStockService.changeFilter.subscribe( data => {
         });
     */
  }
  
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
  }

  searchStock():void {
    this.filterStockEvent.emit({
      product : this.productStock,
      entity : this.entityType
    });
  }

  enabledButton():boolean {
    return this.requiredAllFilter ? this.allFilterRequired() : this.justOneFilterRequierd();
  }
  
  allFilterRequired(): boolean {
    return this.productStock === undefined || (this.entityType === undefined || this.entityType === '')
  }

  justOneFilterRequierd(): boolean {
   return this.productStock === undefined && (this.entityType === undefined || this.entityType === '')
  }

  readOnlyFilter ():boolean {
    return this.filterSkuReadOnly;
  }

  selectedEntity(event:any):void {
    this.entityType = event;
  }

  selectedProduct(event:any):void {
    this.productStock = event;
  }

}
