import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
//import { Apollo } from 'apollo-angular';
//import gql from 'graphql-tag';

import { environment } from '../../../environments/environment';
//import { Session } from '../interfaces';
import { Entity } from '../model/interface/entity';

@Injectable({
  providedIn: 'root'
})
export class EntityService {
  constructor(
    //private apollo: Apollo
    ) { }


  getLocalStores(): Entity[] {
    const session: any /* Session*/ = JSON.parse(
      localStorage.getItem(environment.sessionAuth) || '{}'
    );
    return session && session.stores ? session.stores : [];
  }

  getStores(): Promise<Entity[]> {
    return new Promise((resolve, reject) => {
      const session: any /* Session*/ = JSON.parse(
        localStorage.getItem(environment.sessionAuth)  || '{}'
      );
      if (session && session.stores) {
        resolve(session.stores);
      }/* else {
        this.apollo
        .watchQuery({
          query: gql`
            query getStores(
              $limit: Int
              $offset: Int
              $entityType: EntityType
              $enabled: Boolean
            ) {
              listEntities(
                limit: $limit
                offset: $offset
                entityType: $entityType
                enabled: $enabled
              ) {
                entityCode
                entityType
                entityName
                ubigeoCode
                managingEntities
                pickingSystemCode
              }
            }
          `,
          variables: {
            entityType: 'TD',
            enabled: true
          }
        })
        .valueChanges.pipe(
          map((response: any) => response.data.listEntities)
        )
        .subscribe(
          stores => {
            session.stores = stores;
            localStorage.setItem(
              environment.sessionAuth,
              JSON.stringify(session)
            );
            resolve(stores);
          },
          error => reject(error)
        );
      } */
    }); 
  }

}
