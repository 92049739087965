    <mat-form-field class="example-full-width  custom-width">
        <mat-label>Buscar entidad</mat-label>
        <input type="text"
               [readonly]="readOnly" 
               matInput
               [formControl]="formControlEntityCodeSearch"
               [matAutocomplete]="autoEntity">
       <button *ngIf="formControlEntityCodeSearch.value" type="button" mat-icon-button matSuffix>
           <mat-icon class="button-clear-filter" (click)="cleanEntity()" matTooltip="Borrar">highlight_off</mat-icon>
       </button>
        <mat-autocomplete autoActiveFirstOption #autoEntity="matAutocomplete"  (optionSelected)="selectEntity($event.option)" [displayWith]="displayFnEntity">
          <mat-option value="">Seleccione una opción</mat-option>
          <mat-option *ngFor="let option of filteredEntityCodeOptions | async" [value]="option">
            {{ option.entityCode }} - {{ option.entityName }} 
          </mat-option>
        </mat-autocomplete>
    </mat-form-field>
