import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ApiService } from './api.service';
import { User } from '../model/user.model';
import { MODULE_DEFAULT } from 'src/app/shared/constant/global';
import { EntityService } from './entity.service';
import { TempStorageService } from './temp-storage.service';
import { SnackbarService } from 'src/app/shared/component/snackbar/snackbar.service';

export const ROLES = {
  ADMINISTRADOR: 'ADMINISTRADOR',
  CENTRALIZED_STOCK: 'CENTRALIZED_STOCK'
};

@Injectable()
export class AuthService extends TempStorageService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private userIdentity = new BehaviorSubject<any>({});
  constructor(
    public apiService: ApiService,
    private entityService: EntityService,
    private snackbarService: SnackbarService
  ) {
    super();
    this.loggedIn.next(this.isAuthenticated());
    this.userIdentity.next(this.getUser());
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  get user() {
    return this.userIdentity.asObservable();
  }

  async login(body: any): Promise<User> {
   const user$  = this.apiService.post(this.apiService.login, body);
   const user: User = await lastValueFrom(user$);
   if (!this.verifyRoles(ROLES.CENTRALIZED_STOCK, user)){
    this.showErros("Usuario sin permiso, comuníquese con soporte");
   } else {
    user.entity.entityCode = user.entity.entityId.toString();
    if (user.visibleModules.length) {
      user.visibleModules = user.visibleModules.concat(MODULE_DEFAULT);
    }
    this.setSession({ user });
    this.loggedIn.next(true);
    this.userIdentity.next(user);   
   }
   return user;
  }

  changePassword(request : any): Observable<any> {
    return this.apiService
      .put(`${this.apiService.changePassword}`, request)
      .pipe(map(item => item));
  }

  logout() {
    this.loggedIn.next(false);
    localStorage.removeItem(environment.sessionAuth);
    sessionStorage.removeItem(environment.sessionData);
    this.removeItems();
  }
  /**
   * Gets user
   * @returns user
   */
  getUser()  {
    if (localStorage.getItem(environment.sessionAuth)) {
      const session = JSON.parse(localStorage.getItem(environment.sessionAuth) || '{}');
      return session.user;
    } else {
      this.logout();
      return null;
    }
  }

  getCompanyCode(): string{
    return this.getUser().entity.company.code;
  }
  
  /**
   * Verificar arreglo de roles con los roles que tiene el usuario
   * si uno de los roles son correctos retorna true, caso contrario false
   * @param rol: any
   */
  verifyRoles(rol : any, userParam?: User) {
    const user: User  =  !userParam ? this.getUser(): userParam;
    if (user && user.roles && user.roles.includes(rol)) {
      return true;
    }
    return false;
  }
  
  /**
   * Sets session
   * @param session: any
   */
  private setSession(session: any): void {
    session.expires_in = 1000 * 60 * 60 * 10 + new Date().getTime();
    localStorage.setItem(environment.sessionAuth, JSON.stringify(session));
  }
  /**
   * Determines whether authenticated is
   * @returns true if authenticated
   */
  public isAuthenticated(): boolean {
    if (localStorage.getItem(environment.sessionAuth)) {
      const session = JSON.parse(localStorage.getItem(environment.sessionAuth) || '{}');
      if (new Date().getTime() < session.expires_in) {
        return true;
      } else {
        this.logout();
        return false;
      }
    } else {
      return false;
    }
  }
  /**
   * Removes items picking
   */
  private removeItems() {
    // localStorege por usuario
    this.removeListStorage(Object.keys(localStorage));
    this.removeListSession(Object.keys(sessionStorage));
  }

  public showErros(erros  : string = "Hubo un error con el usuario"):void {
    this.snackbarService.show(erros, "error");
  }
}
