import { Component, Input, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable, debounceTime, map, startWith, switchMap } from 'rxjs';
import { Product } from 'src/app/core/model/interface/product.interface';
import { ProductService } from 'src/app/core/service/product.service';
import { EventEmitter } from '@angular/core';
import { LoaderService } from 'src/app/core/service/loader.service';

@Component({
  selector: 'app-stock-filter',
  templateUrl: './stock-filter.component.html',
  styleUrls: ['./stock-filter.component.scss']
})
export class StockFilterComponent implements OnInit, OnDestroy {

  formControlProduchSearch = new FormControl();
  @Output() selectedProduct: EventEmitter<any> = new EventEmitter();
  @Input() requiredAllFilter: boolean = false;
  @Input() product: Product | any; 
  @Input() readOnly: boolean = false;

  public filteredProductOptions: Observable<Product[]>;
  public productStock:Product | any;
  public filterSkuReadOnly: boolean = false;

  constructor(private productService: ProductService, private loaderService: LoaderService) {
    this.filteredProductOptions = this.formControlProduchSearch.valueChanges.pipe(
      debounceTime(20),
      startWith(''),
      switchMap(value =>  {
            if (typeof value === 'string' && value.trim().length >= 3) {
              value = value.split("/");
              value = value[0].toString().trim();
              return this.productService.getProductBySku(value).pipe(map(data => data)) 

            }
            if (value) {
              if (value === '' || value.toString().trim().length <= 3) {
                this.productStock = undefined;
              }
            }
           
          return  [[]]
      } ));

      if (this.productStock) {
        this.formControlProduchSearch.setValue(this.productStock);
      }
     /*
     TODO PARA OBTENER CAMBIOS DESDE UN COMPONENTE PADRE
         this.filterStockService.changeFilter.subscribe( data => {
         });
     */
  }
  
  ngOnChanges(changes: SimpleChanges) {
    this.formControlProduchSearch.setValue(this.product);
    this.filterSkuReadOnly = this.readOnly;
  }

  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.formControlProduchSearch.setValue(this.product);
    if (!this.readOnly) {
      this.loaderService.getSpinnerObserver().subscribe((status) => {
        this.filterSkuReadOnly = (status === 'start');
      })
    }
;
  }

  selectProduct(product: any):void {
    this.productStock = undefined;
    if (product.value) {
      this.productStock = product.value
    }
    this.selectedProduct.emit(this.productStock);
  }


  displayFn(prod:any):string {
    return prod ? prod.skuCode + ' / ' + prod.description : '';
  }

  readOnlyFilter ():boolean {
    return this.filterSkuReadOnly;
  }

  cleanSku() {
    this.formControlProduchSearch.setValue(null);
    this.productStock  = undefined;
    this.selectedProduct.emit(undefined);
  }

}
