// estandar de mensages en snackbar
export const alertMessage = {
  successUpdate: 'Actualización satisfactoria.',
  successDelete: 'Se eliminó satisfactoriamente.',
  successSave: 'Registro satisfactorio.',
  successConfiguration: 'Configuración satisfactoria.',
  formRequired: 'Falta completar  y/o corregir los campos obligatorios con caracteres validos.',
  levelsRequired: 'Debe asignar al menos un producto o categoría.',
  optionWithoutData: 'Aún no existe información registrada.',
  extraFormRequired: 'Debe completar el formulario de Datos de Entidad',
  coordinatesValidate: 'Indicar las coordenadas en el mapa',
  entityCodeExists: 'El código de entidad ya existe',
  errorStock : 'Hubo error el guardado del stock'
};

export function getMessage(alertMessages : any, keyError: string): string {
  const messages = alertMessages[keyError];
  return messages ? messages : 'Hubo un error en el servicio';

}


