
<mat-form-field class="example-full-width padding-rigth custom-width" >
  <mat-label>Buscar producto por SKU</mat-label>
  <input type="text"  [readonly]="readOnlyFilter()" matInput [formControl]="formControlProduchSearch" [matAutocomplete]="auto">
  <button *ngIf="formControlProduchSearch.value" type="button" mat-icon-button matSuffix>
    <mat-icon class="button-clear-filter" (click)="cleanSku()" matTooltip="Borrar">highlight_off</mat-icon>
</button>
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"  (optionSelected)="selectProduct($event.option)" [displayWith]="displayFn">
    <mat-option value="">Seleccione una opción</mat-option>
    <mat-option *ngFor="let option of filteredProductOptions | async" [value]="option">
      {{ option.skuCode }} / {{ option.description }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
