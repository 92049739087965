import { Injectable } from "@angular/core";
import { Stock } from "../model/interface/stock.interface";
import { ApiService } from "./api.service";
import { Observable, map } from "rxjs";
import { AuditRequest, AuditResponse, Process, ProcessMesages, ProcessMesagesData } from "../model/interface/process.interface";
import * as moment from "moment";

@Injectable()
export class ProcessService {


 constructor(private apiClient : ApiService) {}

 getProcessFilterByDatePaginate(date: string, dateEnd:string, processCode:string ,page: number ,size: number = 10):Observable<Process> {
   const request = {
      dateCreatedFrom: date,
      dateCreatedTo: dateEnd,
      processCode: processCode,
      page : page,
      size : size
   }
  return this.apiClient.post(`${this.apiClient.centralizedStockJob}/process/filter`, request).pipe(map(data => data));
 }

 getAdutitFilterByDatePaginate(auditRequest: AuditRequest,page: number ,size: number = 10):Observable<AuditResponse> {
  
  return this.apiClient.post(`${this.apiClient.centralizedStockRead}/audit?page=${page}&size${size}`, auditRequest).pipe(map(data => data));
 }

 getMessageByProcessIdPaginate(processId: number, page: number ,size: number = 10):Observable<ProcessMesages> {
  return this.apiClient.get(`${this.apiClient.centralizedStockJob}/messages/${processId}/page/${page}/size/${size}`).pipe(map((data: ProcessMesages) => data));
 }

 reProcessMessages(messageId: number):Observable<ProcessMesagesData> {
    return this.apiClient.post(`${this.apiClient.centralizedStockJob}/messages/${messageId}/reprocess`).pipe(map((data: ProcessMesagesData) => data));
   }
}
