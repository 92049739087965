 <div class="container-login d-flex justify-content-center align-items-center column">
    <div class="mx-auto form-login">
      
      <mat-card class="center-block container-login-form">
        <mat-spinner diameter="50" class="spinner" *ngIf="this.isSubmitting"></mat-spinner>
        <div *ngIf="!this.isSubmitting">
          <mat-card-header class="justify-content-center header-login">
           <!-- <img mat-card-image src="../../../assets/home/logo-white/Group2.svg" alt="logo-dad" /> -->
            <mat-card-title class="text-center">Bienvenido</mat-card-title>
            <label class="version" *ngIf="!isNotProd">v.{{version}}</label>
          </mat-card-header>
          <mat-divider></mat-divider>
          <mat-card-content class="d-flex flex-column justify-content-between py-3">
            <form
              action=""
              [formGroup]="form"
              class="form-login-input"
              (ngSubmit)="onSubmit()"
            >
              <div class="d-flex flex-column py-2">
                <div class="d-flex flex-column">
                  <mat-form-field>
                    <input
                      matInput
                      id="username"
                      type="text"
                      placeholder="Escriba su usuario"
                      formControlName="username"
                      autocomplete="off"
                    />
                    <mat-icon matSuffix class="icon-visibility">person</mat-icon>
                    <mat-error
                      *ngFor="let validation of validation_messages.user"
                    >
                      <ng-container
                        *ngIf="
                          form.get('username')?.hasError(validation.type) &&
                          (form.get('username')?.dirty ||
                            form.get('username')?.touched)
                        "
                      >
                        {{ validation.message }}
                      </ng-container>
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="d-flex flex-column  py-2">
                <mat-form-field>
                  <input
                    matInput
                    id="password"
                    style="text-transform: none !important"
                    placeholder="Escriba su contraseña"
                    formControlName="password"
                    (keyup.enter)="form.valid ? onSubmit() : null"
                    [type]="hidePassword ? 'password' : 'text'"
                    autocomplete="new-password"
                  />
                  <mat-icon
                    matSuffix
                    id="hidePassword"
                    class="icon-visibility"
                    (click)="hidePassword = !hidePassword"
                  >
                    {{ hidePassword ? "visibility_off" : "visibility" }}
                  </mat-icon>
                  <mat-error
                    *ngFor="let validation of validation_messages.password"
                  >
                    <ng-container
                      *ngIf="
                        form.get('password')?.hasError(validation.type) &&
                        (form.get('password')?.dirty || form.get('password')?.touched)
                      "
                    >
                      {{ validation.message }}
                    </ng-container>
                  </mat-error>
                </mat-form-field>
              </div>
            </form>
          </mat-card-content>
          <mat-card-footer
            class="d-flex flex-column justify-content-center py-3 footer-login"
          >
            <button
              mat-flat-button
              class="button-success"
              [disabled]="form.invalid"
              (click)="onSubmit()"
            >
              Iniciar sesión
            </button>
  
          </mat-card-footer>
        </div>

       
      </mat-card>
      <div class="row logo">
        <img
          src="../../../assets/home/logo-white/logo_promart.svg"
          alt="logo-promart"
          class="col-md-3"
        />
        <img
          src="../../../assets/home/logo-white/logo_oechsle.svg"
          alt="logo-oe"
          class="col-md-3"
        />
        <img
          src="../../../assets/home/logo-white/logo_spsa.svg"
          alt="logo-spsa"
          class="col-md-3"
        />
        <img
          src="../../../assets/home/logo-white/logo_realplaza_horizontal.svg"
          alt="logo-rp"
          class="col-md-3"
        />
      </div>
    </div>
    <div class="d-flex fixed-bottom justify-content-end">
      <img src="../../../assets/home/logo-white/intercorp-vector.svg" alt="company" class="vector" />
    </div>
  </div> 

  
  