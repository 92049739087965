export const MODULE_DEFAULT = [
    {
      active: true,
      moduleDescription: "Administración de cuenta",
      moduleId: 0,
      moduleIdentifier: "administrar-cuenta",
      moduleName: "administrar-cuenta"
    }
  ]


  export const ENUMS_ACCTION_CRUD_STOCK = {
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DETAIL: 'DETAIL'
  }

  export const ENUMS_ORIGIN = {
    CENTRALIZED_STOCK: 'web-centralized-stock'
  }

  export const STOK_TYPE = {
    DISP: 'DISP'
  }