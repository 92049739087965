import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  CanActivate
} from '@angular/router';
import { AuthService } from '../service/auth.service';
import { PATH_MENU } from '../config/global';


@Injectable()
export class NoAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.auth.isAuthenticated()) {
      return true;
    } else {
      this.router.navigate([PATH_MENU.home.path]);
      return false;
    }
  }
}
