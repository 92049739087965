export class TempStorageService {

    /**
     * Sets storage
     * @param key: string
     * @param item: any
     * @returns
     */
    setStorage(key: string, item: any) {
        return localStorage.setItem(key, JSON.stringify(item));
    }

    /**
     * Gets storage
     * @param key: string
     * @returns
     */
    getStorage(key: string) {
        try {
            const result = JSON.parse(localStorage.getItem(key) || '{}') || undefined;
            return result;
        }catch (error) {
            console.log('Error removiento storage -> ', key, '-- error ', error)
            return key;

        }
 
    }

    /**
     * Removes storage
     * @param key: string
     */
    removeStorage(key: string) {
        if (this.getStorage(key)) {
            localStorage.removeItem(key);
        }
    }

    /**
     * Removes list storage for picking
     * @param keys: string[]
     */
    removeListStorage(keys: string[]) {
        for (const key of keys) {
            if (this.getStorage(key)) {
                localStorage.removeItem(key);
            }
        }
    }

    removeListSession(keys: string[]) {
        for (const key of keys) {
            sessionStorage.removeItem(key);
        }
    }
}

